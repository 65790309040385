@use '../../base';
@import 'styles/mixins';

.search_container {
  position: relative;
  top: -16px;
}

.search_input_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 777px;
  height: 60px;
  padding: 20px;
  background: #fff;
  border: 1px solid #c9cdd4;
  border-right: none;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @include base.respond-to(phone) {
    width: 2.94rem;
    height: 0.48rem;
    padding: 0.12rem 0.08rem;
    padding-left: 0;
  }
}

.search_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 145px;
  height: 60px;
  padding: 12px 20px;
  font-family: 'Product Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
  cursor: pointer;
  background: var(--main-color-blue-blue-012378-e-5, #2378e5);
  border-radius: 0 8px 8px 0;
  transition: all 0.3s linear;

  // &:hover {
  //   background-color: lighten($color: #2378e5, $amount: 10);
  // }

  &:active {
    background-color: lighten($color: #2378e5, $amount: 20);
  }

  @include base.respond-to(phone) {
    // width: 0.56rem;
    min-width: 0.56rem;
    height: 0.48rem;
    padding: 0.12rem;
    font-size: 0.17rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.22rem;
  }
}

.button_loading {
  cursor: not-allowed !important;
  background-color: lighten($color: #2378e5, $amount: 20);
}

.filter_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* 防止内容换行 */
  width: 1136px;
  margin: 0 auto;
  overflow-x: auto;

  /* 允许横向滚动 */
  white-space: nowrap;

  @include base.respond-to(phone) {
    width: auto;

    .arrow_left {
      display: none;
    }

    .arrow_right {
      display: none;
    }
  }
}

.search_input_wrapper {
  display: flex;
  justify-content: center;
  padding-top: 32px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    padding-top: 0;
    margin-top: 0.36rem;
  }
}

.location_selector_wapper {
  min-width: 128px;

  @include base.respond-to(phone) {
    min-width: 1rem;
    padding-left: 0.08rem;
  }
}

.location_input {
  appearance: none;
  border: none;
  outline: none;

  @include base.respond-to(phone) {
    width: 0.58rem;

    @include text_overflow;
  }
}

.location_input_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.location_input_border {
  height: 44px;
  margin: 0 12px;
  border-right: 1px solid #c9cdd4;

  @include base.respond-to(phone) {
    position: absolute;
    right: 0.08rem;
    height: 0.23rem;
    margin: 0;
    border-right: 1px solid #e5e6eb;
  }
}

.job_search_container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  height: 68px;
  padding-left: 15px;

  @include base.respond-to(phone) {
    position: relative;
    padding-left: 0;
  }
}

.job_search_input {
  position: relative;
  flex: 1;
  padding-left: 10px;
  appearance: none;
  border: none;
  outline: none;

  @include base.respond-to(phone) {
    width: 1rem;
    padding-left: 0.2rem;
  }
}

.job_prefix {
  @include base.respond-to(phone) {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 0.16rem;
    height: 0.16rem;
  }
}

.location_arrow {
  position: absolute;
  top: 17px;
  right: 45px;
  width: 8px;
  height: 8px;
  border-right: 1px solid #4e5969;
  border-bottom: 1px solid #4e5969;
  transform: rotate(45deg);

  @include base.respond-to(phone) {
    top: 0.07rem;
    right: 0.17rem;
    width: 0.07rem;
    height: 0.07rem;
  }
}

.content_layout {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 1136px;
  min-height: 300px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    width: 3.58rem;
    margin: 0 auto;
    margin-top: 0.08rem;
  }
}

.loading_wrapper {
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1136px;
  min-height: 300px;
  margin: 0 auto;
}

.title {
  // width: 446px;
  display: -webkit-box;
  flex: 1;
  overflow: hidden;
  font-family: 'Product Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #121212;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;

  &:hover {
    color: #136fd3;
  }

  @include base.respond-to(phone) {
    // line-height: 0.22rem;
    display: -webkit-box;
    margin-bottom: 0.08rem;
    overflow: hidden;
    font-family: 'Product Sans';

    // height: 0.22rem;
    font-size: 0.17rem;
    font-style: normal;
    font-weight: 500;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    // width: 2.3rem;
    word-break: break-all;
    white-space: pre-wrap;
    -webkit-box-orient: vertical;
  }
}

.mobile_tag {
  display: none;

  @include base.respond-to(phone) {
    font-family: 'Product Sans';

    /* Regular/Body/Regular/medium */
    font-size: 0.12rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.21rem;
    color: var(--neutral-color-text, #86909c);
  }
}

.location {
  margin-left: 10px;
  overflow: hidden;
  font-size: 12px;
  color: #515151;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  @include base.respond-to(phone) {
    font-family: 'Product Sans';
    font-size: 0.12rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.28rem;
    color: var(--neutral-color-text, #86909c);
    letter-spacing: 0.09px;
  }
}

.search_card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 560px;
  height: auto;
  min-height: 128px;
  padding: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ffff;
  border-radius: 8px;
  transition: all 0.3s linear;

  &:hover {
    border: 1px solid var(--brand-color, #2378e5);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);

    .name {
      opacity: 0;
    }

    .chat_now {
      opacity: 1;
    }
  }

  @include base.respond-to(phone) {
    width: calc(100vw - 20px);
    padding: 16px;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid var(--neutral-color-border, #f2f3f5);
    border-radius: 12px;

    &:hover {
      border: 1px solid #fff;
      box-shadow: none;
    }
  }
}

.search_title_layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0;

  .urgentLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1px 3px;
    margin-right: 8px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    color: #fffefe;
    background: linear-gradient(128deg, #f64a88, #f56a6a);
    border-radius: 4px;
    box-shadow: 0 4px 13px 0 rgba(255, 60, 60, 0.1);
  }
}

.mobile {
  display: none;

  @include base.respond-to(phone) {
    display: inherit;
  }
}

.desktop {
  @include base.respond-to(phone) {
    display: none;
  }
}

.content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;

  :global {
    .tag_flag {
      margin-top: 10px;

      // padding: 5px 10px;
      background: #f7f8fa;
      border-radius: 6px;
    }
  }

  @include base.text_overflow;

  @include base.respond-to(phone) {
    justify-content: flex-start;
    margin-top: 0;

    :global {
      span {
        display: block;
        flex-shrink: 0;
        margin-right: 0.14rem;
        font-family: 'Product Sans';

        /* Regular/Body/Regular/medium */
        font-size: 0.14rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.21rem;
        color: var(--neutral-color-text, #86909c);

        &::after {
          position: relative;
          left: 0.07rem;
          font-size: 12px;
          content: ' | ';
        }

        &:last-child {
          &::after {
            content: '';
          }
        }
      }

      .tag_flag {
        padding: 0.05rem 0.1rem;
        background: var(--neutral-color-fill, #f7f8fa);
        border-radius: 0.06rem;
      }
    }
  }
}

.jobcard_salary_wrapper {
  position: relative;
  width: fit-content;

  // overflow: hidden;
  // text-overflow: ellipsis;
  padding-bottom: 0;
  white-space: nowrap;

  @include base.respond-to(phone) {
    width: fit-content;
  }
}

.salary {
  width: 100%;
  padding-top: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #2378e5;
  text-align: right;

  @include base.respond-to(phone) {
    position: static;
    margin-bottom: 0.08rem;
    font-size: 0.17rem;
    font-weight: 400;
    line-height: 0.23rem;
    color: var(--main-color-blue-blue-012378-e-5, #2378e5);
    text-align: right;

    @include text_overflow;
  }
}

.tags {
  display: flex;
}

.tag_item {
  min-width: 67px;
  height: 25px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #515151;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border-radius: 6px;

  @include text_overflow;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  margin-top: 18px;
  white-space: nowrap;

  @include base.respond-to(phone) {
    margin-top: 0.16rem;
  }
}

.name {
  display: flex;
  align-items: center;

  :global {
    span {
      display: block;
      max-width: 100px;
      overflow: hidden;
      font-size: 14px;
      color: #515151;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
    }
  }
}

.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  border-radius: 50%;

  :global {
    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  @include base.respond-to(phone) {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.07rem;
  }

  .status {
    position: absolute;
    right: 0;
    bottom: 0;
    flex: none;
    width: 8px;
    height: 8px;
    background: #0ebd5c;
    border: 1px solid #fff;
    border-radius: 50%;
  }
}

.chat_footer {
  display: flex;
  align-items: center;
}

.chat_now {
  position: absolute;
  top: 85px;
  left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  padding: 7px 18px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: right;
  letter-spacing: 0.0075em;

  // min-height: 28px;
  background: #2378e5;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s linear;

  &:hover {
    color: #fff !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 60px;

  @include base.respond-to(phone) {
    padding-top: 0.2rem;
    padding-bottom: 0.3rem;
  }

  :global {
    .MuiPaginationItem-page {
      width: 40px;
      height: 40px;
      font-size: 16px;
      font-weight: 700;
      background-color: #fff;
      border-radius: 4px;
    }

    .Mui-selected {
      background: #2378e5;
    }

    @include base.respond-to(phone) {
      .MuiPaginationItem-icon {
        font-size: 0.24rem;
      }

      .MuiPaginationItem-sizeMedium {
        font-size: 0.14rem;
      }
    }
  }
}

.search_container {
  :global {
    .scroll-container {
      overflow-x: auto;

      /* 允许横向滚动 */
      white-space: nowrap;

      /* 防止内容换行 */
    }

    .scroll-content {
      display: inline-block;

      /* 将内容包裹在内联块中 */
    }

    .item {
      display: inline-block;

      /* 将每个项放置在同一行 */
      width: 200px;

      /* 设置项的宽度 */
      height: 200px;
      margin-right: 10px;

      /* 设置项的高度 */
      background-color: #ccc;

      /* 设置项之间的右边距 */
    }
  }
}

.filter_layout {
  display: flex;
  min-height: 90px;
  padding-top: 26px;
  padding-bottom: 10px;
  overflow: hidden;

  @include base.respond-to(phone) {
    /* 防止内容换行 */
    padding-top: 0.2rem;
    padding-bottom: 0;
    overflow-x: auto;

    /* 允许横向滚动 */
    white-space: nowrap;
  }
}

.filter_wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  // min-height: 90px;
  transition: transform 0.3s ease;
  transform: translate3d(0, 0, 0);

  @include base.respond-to(phone) {
    margin: 0 0.2rem;
    transition: none;
    transform: none;
  }
}

.filter_tag {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  min-width: 57px;
  height: 39px;
  padding: 8px 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: #121212;
  cursor: pointer;
  background: #f6f6f6;
  border-radius: 8px;

  &:hover {
    color: #fff;
    background-color: #5398f5;
  }

  @include base.respond-to(phone) {
    &:hover {
      color: #1d2129;
      background: #f7f8fa;
    }
  }
}

.search_content_wrapper {
  background-color: #f5f7fb;
}

.filter_split {
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--neutral-color-border, #f2f3f5);
}

.active {
  color: #fff !important;
  background: #2378e5 !important;
}

.filter_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.1s linear;

  &::after {
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    border-top: 1px solid #000;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid #000;
  }

  &:hover {
    background-color: #2378e5;

    &::after {
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
    }
  }

  &:active {
    background-color: lighten($color: #2378e5, $amount: 20);
  }
}

.arrow_left {
  @extend .filter_arrow;

  &::after {
    position: relative;
    left: 2px;
    transform: rotate(-45deg);
  }

  margin-right: 10px;
}

.arrow_right {
  @extend .filter_arrow;

  &::after {
    position: relative;
    right: 2px;
    transform: rotate(135deg);
  }

  margin-left: 10px;
}

.opacity {
  cursor: inherit !important;
  opacity: 0 !important;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  // justify-content: space-between;
  // width: calc(50vw + $paddingLeftWidth);
  max-width: 1136px;
  padding-top: 32px;
  margin: 0 auto;

  // padding-left: 24px;
  // padding-right: 24px;

  &Left {
    display: flex;
    align-items: center;

    // width: $contentWidth;
  }

  &Right {
    display: flex;
    margin-left: 22px;
  }

  .filterItems {
    width: 198px;
    height: 32px;
    margin-right: 8px;
    background: #fff;
    border: 1px solid #e5e6eb;
    border-radius: 4px;

    :global {
      .MuiOutlinedInput-root {
        background-color: #fff !important;
      }

      .MuiOutlinedInput-input {
        background-color: #fff !important;
      }
    }
  }

  .jobFunction {
    min-width: 152px;
  }

  .jobSalary {
    min-width: 128px;
  }

  .moreButton {
    min-width: 140px;
    min-width: auto;
    padding: 0 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #707070;
    text-transform: inherit;
    letter-spacing: 0.28px;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #e5e6eb;
    border-radius: 4px;
  }

  .moreButtonCount {
    color: #136fd3;
  }

  .clearButton {
    align-items: center;
    margin-left: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    color: #707070;
    text-transform: inherit;
    letter-spacing: 0.0075em;
  }
}

.resetFilters {
  cursor: pointer;
}

.newSearchInput {
  :global {
    .ant-input-affix-wrapper {
      width: 202px;
      background: #fff;
      border: 1px solid #e5e6eb;
      border-radius: 4px;
    }
  }
}
