@use '../../base';
@import 'styles/mixins';

.mask {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
}

.mobileCard {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 6;
  // transition: 0.01s;
  border-radius: 24px 24px 0px 0px;
  bottom: 0;
  background: #fff;
  transform: translateY(80vh);

  .line {
    width: 37px;
    height: 3px;
    border-radius: 22px;
    background: #bbc6e4;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
  }

  .header {
    // border-radius: 24px 24px 0px 0px;
    height: 68px;
    padding: 10px 24px 0;
    background: linear-gradient(177deg, #dae6ff 0.87%, #fff 96.96%);
    display: flex;
    align-items: center;

    .menu {
      color: #7d7d7d;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.15px;
      margin-right: 28px;
      position: relative;

      sup {
        color: #7d7d7d;
      }

      &.menuActive {
        color: #121212;

        &::after {
          content: ' ';
          width: 11px;
          height: 5px;
          border-radius: 6px;
          position: absolute;
          left: 50%;
          bottom: -6px;
          transform: translateX(calc(-50% - 10px));
          background-color: #2378e5;
        }
      }
    }
  }

  .cardMain {
    height: calc(100% - 68px);
  }
}