@use '../../base';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px;
  border-radius: 8px;
  background: #fff;
  width: 368px;
}

.containerMore {
  display: flex;
  justify-content: center;
  width: 100%;


}

.chatItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-radius: 6px;
  background: #F6F6F6;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: #F4F8FE;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include base.respond-to(phone) {
    margin-bottom: 0;
    border-radius: 6px;
    background: #F6F6F6;
    padding: 8px;
    margin-right: 8px;
    width: 160px;

    img {
      border-radius: 50% !important;
    }
  }
}

.chatHeader {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 10px;

  @include base.respond-to(phone) {
    width: 38px;
    height: 38px;
  }
}


.chatTitle {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.0075em;
  color: #1d2129;
  width: 240px;
  @include base.text_overflow;

  @include base.respond-to(phone) {
    width: 100px;
    color: #121212;
    margin-bottom: 0;
  }
}

.chatSubtitle {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #86909c;
  width: 240px;
  @include base.text_overflow;

  @include base.respond-to(phone) {
    width: 100px;
    color: #7D7D7D;
  }
}

.title {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #121212;
  margin-bottom: 14px;
}

.animation_wrapper {
  overflow: hidden;
  transition: height 0.3s linear;
}

.more {
  color: #2378E5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  margin-top: 32px;
  padding-right: 22px;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #2378E5;
    right: 0px;
    bottom: 7px;
  }
}

.lessMore {
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #2378E5;
    right: 0px;
    bottom: 8px;
  }
}