@use '../../base';
@import 'styles/mixins';

.search_input_layout {
  width: 100%;
  height: 39px;
  padding: 8px 12px;
  margin: 0 10px;
  background: #f7f8fa;
  border-radius: 8px;
}

.filterItems {
  height: 32px;
  background: #fff;

  :global {
    .MuiInputLabel-root {
      display: none;
    }

    .MuiOutlinedInput-root {
      height: 30px;
      padding-right: 0;
      background-color: #fff;
    }

    input {
      height: 0;
      font-size: 14px !important;
      background-color: #fff !important;
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }

    .isMobile {
      top: 0;
    }
  }
}

.filter_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* 防止内容换行 */
  width: 1136px;
  margin: 0 auto;
  overflow-x: auto;

  /* 允许横向滚动 */
  white-space: nowrap;

  @include base.respond-to(phone) {
    width: auto;

    .arrow_left {
      display: none;
    }

    .arrow_right {
      display: none;
    }
  }
}

.search_input_wrapper {
  display: flex;
  justify-content: center;
  padding: 0 9px;
  margin: 0 auto;
}

.location_selector_wapper {
  min-width: 128px;

  @include base.respond-to(phone) {
    min-width: 1rem;
    padding-left: 0.08rem;
  }
}

.location_input {
  appearance: none;
  border: none;
  outline: none;

  @include base.respond-to(phone) {
    width: 0.58rem;

    @include text_overflow;
  }
}

.location_input_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.location_input_border {
  height: 44px;
  margin: 0 12px;
  border-right: 1px solid #c9cdd4;

  @include base.respond-to(phone) {
    position: absolute;
    right: 0.08rem;
    height: 0.23rem;
    margin: 0;
    border-right: 1px solid #e5e6eb;
  }
}

.job_search_container {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
}

.job_search_input {
  position: relative;
  flex: 1;
  width: 1rem;
  height: 23px;
  padding-left: 10px;
  font-size: 16px;
  text-indent: 12px;
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;

  // transform: scale(0.75);
  // transform-origin: left;
  // text-indent: 20px;
}

.job_prefix {
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 10;
  width: 0.16rem;
  height: 0.16rem;
}

.location_arrow {
  position: absolute;
  top: 17px;
  right: 45px;
  width: 8px;
  height: 8px;
  border-right: 1px solid #4e5969;
  border-bottom: 1px solid #4e5969;
  transform: rotate(45deg);

  @include base.respond-to(phone) {
    top: 0.07rem;
    right: 0.17rem;
    width: 0.07rem;
    height: 0.07rem;
  }
}

.content_layout {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 1136px;
  min-height: 300px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    width: 3.58rem;
    margin: 0 auto;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1136px;
  min-height: 300px;
  margin: 0 auto;
}

.title {
  // line-height: 0.22rem;
  display: -webkit-box;
  margin-bottom: 0.08rem;
  overflow: hidden;
  font-family: 'Product Sans';

  // height: 0.22rem;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #121212;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  word-break: break-all;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
}

.mobile_tag {
  display: none;

  @include base.respond-to(phone) {
    font-family: 'Product Sans';

    /* Regular/Body/Regular/medium */
    font-size: 0.12rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.21rem;
    color: var(--neutral-color-text, #86909c);
  }
}

.location {
  margin-left: 10px;
  overflow: hidden;
  font-size: 0.12rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.28rem;
  color: #515151;
  text-overflow: ellipsis;
  letter-spacing: 0.09px;
  word-break: break-word;
  white-space: nowrap;
}

.search_card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100vw - 20px);
  height: auto;
  padding: 20px 0;
  margin: 0 auto;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #f2f3f5;
  transition: all 0.3s linear;

  &:first-child {
    padding-top: 8px;
  }
}

.search_content_wrapper {
  height: calc(100vh - 152px);
  overflow-y: scroll;
}

.search_title_layout {
  width: 100%;
  padding: 1px 0;

  .urgentLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1px 3px;
    margin-right: 8px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    color: #fffefe;
    background: linear-gradient(128deg, #f64a88, #f56a6a);
    border-radius: 4px;
    box-shadow: 0 4px 13px 0 rgba(255, 60, 60, 0.1);
  }
}

.mobile {
  display: none;

  @include base.respond-to(phone) {
    display: inherit;
  }
}

.desktop {
  @include base.respond-to(phone) {
    display: none;
  }
}

.content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 4px;

  :global {
    .tag_flag {
      margin-top: 10px;

      // padding: 5px 10px;
      background: var(--neutral-color-fill, #f7f8fa);
      border-radius: 6px;
    }
  }

  @include base.text_overflow;

  @include base.respond-to(phone) {
    justify-content: flex-start;
    margin-top: 0;

    :global {
      span {
        display: block;
        flex-shrink: 0;
        height: 25px;
        padding: 0 10px;
        margin-right: 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 0.21rem;
        line-height: 25px;
        color: #515151;
        background: #f7f8fa;
        border-radius: 6px;
      }

      .tag_flag {
        padding: 0.05rem 0.1rem;
        background: var(--neutral-color-fill, #f7f8fa);
        border-radius: 0.06rem;
      }
    }
  }
}

.jobcard_salary_wrapper {
  position: relative;
  width: fit-content;

  // overflow: hidden;
  // text-overflow: ellipsis;
  padding-bottom: 0;
  white-space: nowrap;

  @include base.respond-to(phone) {
    width: fit-content;
  }
}

.salary {
  position: static;
  margin-bottom: 0.08rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 0.23rem;
  color: #2378e5;
  text-align: right;

  @include text_overflow;
}

.tags {
  display: flex;
}

.tag_item {
  min-width: 67px;
  height: 25px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #4e5969;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border-radius: 6px;

  @include text_overflow;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  margin-top: 18px;
  white-space: nowrap;

  @include base.respond-to(phone) {
    margin-top: 0.16rem;
  }
}

.name {
  display: flex;
  align-items: center;

  :global {
    span {
      display: block;
      max-width: 100px;
      overflow: hidden;
      font-size: 14px;
      color: #515151;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
    }
  }
}

.avatar {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  border-radius: 50%;

  :global {
    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  @include base.respond-to(phone) {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.07rem;
  }

  .status {
    position: absolute;
    right: 0;
    bottom: 0;
    flex: none;
    width: 8px;
    height: 8px;
    background: #0ebd5c;
    border: 1px solid #fff;
    border-radius: 50%;
  }
}

.chat_footer {
  display: flex;
  align-items: center;
}

.chat_now {
  position: absolute;
  top: 0;

  // line-height: 18px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  padding: 3px 10px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;

  /* Neutral color/text/纯白文字 */

  color: #fff;

  /* identical to box height, or 150% */

  text-align: right;
  letter-spacing: 0.0075em;

  // min-height: 28px;
  background: #2378e5;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s linear;

  @include base.respond-to(phone) {
    // display: none;
  }

  &:hover {
    color: #fff !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @include base.respond-to(phone) {
    padding-top: 0.2rem;
    padding-bottom: 0.3rem;
  }

  :global {
    @include base.respond-to(phone) {
      .MuiPaginationItem-icon {
        font-size: 0.24rem;
      }

      .MuiPaginationItem-sizeMedium {
        font-size: 0.14rem;
      }
    }
  }
}

.search_container {
  :global {
    .scroll-container {
      overflow-x: auto;

      /* 允许横向滚动 */
      white-space: nowrap;

      /* 防止内容换行 */
    }

    .scroll-content {
      display: inline-block;

      /* 将内容包裹在内联块中 */
    }

    .item {
      display: inline-block;

      /* 将每个项放置在同一行 */
      width: 200px;

      /* 设置项的宽度 */
      height: 200px;
      margin-right: 10px;

      /* 设置项的高度 */
      background-color: #ccc;

      /* 设置项之间的右边距 */
    }
  }
}

.filter_layout {
  display: flex;
  min-height: 90px;
  padding-top: 32px;
  padding-bottom: 24px;
  overflow: hidden;

  @include base.respond-to(phone) {
    /* 防止内容换行 */
    padding-top: 0.2rem;
    padding-bottom: 0;
    overflow-x: auto;

    /* 允许横向滚动 */
    white-space: nowrap;
  }
}

.filter_wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 90px;
  transition: transform 0.3s ease;
  transform: translate3d(0, 0, 0);

  @include base.respond-to(phone) {
    margin: 0 0.2rem;
    transition: none;
    transform: none;
  }
}

.filter_tag {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  min-width: 57px;
  height: 39px;
  padding: 8px 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  color: #1d2129;
  cursor: pointer;
  background: #f7f8fa;
  border-radius: 8px;

  &:hover {
    color: #fff;
    background-color: #5398f5;
  }

  @include base.respond-to(phone) {
    &:hover {
      color: #1d2129;
      background: #f7f8fa;
    }
  }
}

.filter_split {
  width: 100%;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--neutral-color-border, #f2f3f5);
}

.active {
  color: #fff !important;
  background: #2378e5 !important;
}

.filter_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.1s linear;

  &::after {
    display: block;
    width: 10px;
    height: 10px;
    content: '';
    border-top: 1px solid #000;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid #000;
  }

  &:hover {
    background-color: #2378e5;

    &::after {
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
    }
  }

  &:active {
    background-color: lighten($color: #2378e5, $amount: 20);
  }
}

.arrow_left {
  @extend .filter_arrow;

  &::after {
    position: relative;
    left: 2px;
    transform: rotate(-45deg);
  }

  margin-right: 10px;
}

.arrow_right {
  @extend .filter_arrow;

  &::after {
    position: relative;
    right: 2px;
    transform: rotate(135deg);
  }

  margin-left: 10px;
}

.opacity {
  cursor: inherit !important;
  opacity: 0 !important;
}

.select {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px 0;

  > div {
    flex: 1;
  }

  :global {
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }

    .Mui-focusVisible {
      background-color: transparent !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #fff !important;
    }
  }
}

.lineBox {
  height: 8px;
  margin-bottom: 10px;
  background: white;
}

.filters {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
  padding: 8px 20px;

  // overflow: hidden;

  // @include sm {
  //   justify-content: flex-start;
  // }

  // @include msd {
  //   // padding: 10px;
  //   flex-wrap: wrap;
  //   width: 90vw;
  //   min-height: 78px;
  // }

  .filterItems {
    display: flex;
    flex: 0 0 calc(50% - 5px);
    align-items: center;
    justify-content: center;
    height: 64px;
    background: #fff;
    border: 1px solid #e5e6eb;
    border-radius: 4px;

    &:hover {
      background-color: #f2f3f5;

      :global {
        .MuiOutlinedInput-root {
          background-color: #f2f3f5 !important;
        }

        .MuiOutlinedInput-input {
          background-color: #f2f3f5 !important;
        }
      }
    }

    @include msd {
      // flex: 0 1 48%;
      // margin: 0 8px 15px 0;

      // &:nth-child(even) {
      //   margin-right: 0;
      // }

      // flex: inherit;
      width: 50%;
      height: 32px;

      &:hover {
        background-color: transparent !important;

        :global {
          .MuiOutlinedInput-root {
            background-color: transparent !important;
          }

          .MuiOutlinedInput-input {
            background-color: transparent !important;
          }
        }
      }
    }

    :global {
      .MuiSelect-select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .MuiPaper-root {
        width: 300px;

        .MuiAutocomplete-paper {
          width: 300px;
        }
      }

      .MuiSelect-select div {
        width: calc(100% - 13px);
        overflow: hidden;
        line-height: 1.5;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .MuiOutlinedInput-root {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #fafafa !important;
        border-radius: 4px !important;

        &:hover {
          background-color: #f2f3f5 !important;
        }
      }

      .MuiOutlinedInput-input {
        padding-right: 24px !important;
        background: #fafafa !important;

        &:hover {
          background-color: #f2f3f5 !important;
        }
      }
    }
  }

  .clearButton {
    align-items: center;
    justify-content: flex-end;
    width: 156px;
    height: 64px;
    padding-right: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    color: #86909c;
    text-transform: capitalize !important;
    letter-spacing: 0.0075em;

    &:hover {
      background-color: #f2f3f5 !important;

      .reset {
        transition: all 0.3s linear;
        transform: rotate(180deg);
      }
    }

    @include msd {
      justify-content: center;
      height: 44px;

      &:hover {
        background-color: transparent !important;

        .reset {
          transition: all 0.3s linear;
          transform: rotate(180deg);
        }
      }

      // margin: 0 8px 15px 0;
    }
  }
}

.resetFilters {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}
