.card_container {
  display: flex;
  align-items: center;
  padding: 0px;
  min-height: 105px;
  padding: 16px;
  transition: all 0.3s linear;
  border: 1px solid #ffffff;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #F2F3F5;

  .card_left {
    flex: 1;
    width: 0;
  }

  :global {
    .tag_flag {
      background-color: var(--neutral-color-fill, #f7f8fa) !important;
    }
  }

  &:hover {
    // background: #f7f8fa;
    background-color: #ffffff;
    border: 1px solid var(--brand-color, #2378e5);

    /* shadow/卡片hover */
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.1);

    .card_chat_title {
      opacity: 0;
    }

    .card_chat_name {
      opacity: 0;
    }

    .chat_now {
      opacity: 1;
    }

    // :global {
    //   .tag_flag {
    //     background-color: #ffffff !important;
    //   }
    // }
  }
}

.card_chat_content {
  position: relative;
  flex: 1;
}

.card_title {
  min-height: 28px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #1d2129;
  display: block;
  width: 92%;
  padding-bottom: 0 !important;
  @extend .text_overflow;

  &:hover {
    color: #1d2129;
  }
}

.tab {
  background: var(--neutral-color-fill, #f7f8fa) !important;
}

.card_salary {
  height: 23px;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  color: #2378e5;
  margin-bottom: 4px;
}

.card_content {
  width: 100%;
  min-height: 43px;
  display: block;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.card_info_list {
  display: flex;
  align-items: center;
}

.card_chat_wrapper {
  transition: transform 0.3s linear;
  display: flex;
  align-items: center;
}

.recruiter_avatar {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 7px;
}

.card_chat_container {
  position: relative;
  min-width: 180px;
}

.chat_now {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  min-width: 73px;
  min-height: 28px;
  background: #2378e5;
  border-radius: 8px;
  position: absolute;
  left: 0;
  color: #ffffff;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  flex-shrink: 0;
  transition: all 0.3s ease;
}

.text_overflow {
  padding-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.card_chat_title {
  max-width: 120px;
  transition: all 0.3s ease;
  @extend .text_overflow;
}

.card_chat_name {
  transition: all 0.3s ease;
  max-width: 120px;
  @extend .text_overflow;
}