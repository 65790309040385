@use "./base";
@import "styles/mixins";

.container {
  width: 100%;
  margin: 0 auto;
  background: #f5f7fb;
}

.company_info_tab_wrapper {
  padding: 16px 0;
  width: 1366 - 115 - 115px;
  margin: 0 auto;
}

.hot_jobs_wrapper {
  background-color: #fff;
  padding: 28px;
  margin-top: 16px;
  width: 1136px;
  margin: 0 auto;
  border-radius: 8px;
}

.header_cover_container {
  @include base.respond-to(phone) {
    height: 2.7rem;
    width: 100%;
    position: relative;
  }
}

.company_info_wrapper {
  display: flex;
  justify-content: space-between;
}

.company_culture {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header_cover {
  width: 1366px;
  min-height: 200px;
  position: absolute;
  z-index: 1;
  background: linear-gradient(180deg, #287aea 0%, #5cb8ff 100%);
}

.jobs_item {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.0075em;
  color: #c9cdd4;
  padding: 16px;
  width: 346px;
  // height: 91px;
  border-radius: 8px;
  border: 1px solid #f2f3f5;
  background: #fff;
  transition: all 0.3s linear;

  &:hover {
    border: 1px solid #2378e5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    color: #ffffff;

    .chat_now {
      opacity: 1;
      color: #ffffff;
    }

    .salary {
      opacity: 0;
    }
  }

  .header_box {
    display: flex;
  }
}

.jobs_item_layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab_wrapper {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 100;
  background: linear-gradient(100deg, #daedfc 3.52%, #efecfe 96.89%);
  margin-bottom: 16px;
}

.tab_layout {
  width: 1366 - 115 - 115px;
  margin: 0 auto;

  @include base.respond-to(phone) {
    padding-left: 0.04rem;
    width: 100%;
  }

  :global {
    .MuiButtonBase-root {
      font-weight: normal;
    }

    .MuiTab-textColorPrimary {
      text-transform: capitalize;
      color: #1d2129;
      font-size: 18px;
      line-height: 25px;
      font-weight: 400 !important;

      @include base.respond-to(phone) {
        font-size: 0.18rem;
        font-weight: 400;
        line-height: 0.25rem;
      }
    }

    .Mui-selected {
      color: #2378e5;
    }

    .MuiTabs-indicator {
      // background-color: var(--neutral-color-text, #1d2129) !important;
      // height: 3px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      & > span {
        background-color: #2378e5 !important;
        height: 3px !important;
        max-width: 50px;
        display: block;
        width: 50px;
        border-radius: 3px;
      }

      @include base.respond-to(phone) {
        & > span {
          background-color: var(--neutral-color-text, #1d2129) !important;
          height: 3px !important;
          max-width: 50px;
          display: block;
          width: 0.5rem;
          border-radius: 3px;
        }
      }
    }

    .MuiTab-root {
      padding-bottom: 20px;

      @include base.respond-to(phone) {
        padding-bottom: 0.12rem;
      }
    }
  }
}

.header {
  width: 100%;
  min-height: 140px;
  position: relative;
  background: linear-gradient(100deg, #daedfc 3.52%, #efecfe 96.89%);
  padding-bottom: 36px;
  padding-top: 36px;

  @include base.respond-to(phone) {
    width: 100%;
    overflow: visible;
    margin-bottom: 0.32rem;
    padding: 0.36rem 0.2rem;
    height: auto;
  }
}

.header_info {
  position: relative;
  width: 1366px;
  margin: 0 auto;
  padding-left: 117px;
  // padding-top: 88px;

  display: flex;
  z-index: 3;

  @include base.respond-to(phone) {
    padding-left: 0;
    align-items: center;
    width: 100%;
  }
}

.header_logo {
  margin-right: 20px;

  @include base.respond-to(phone) {
    position: relative;
    // position: absolute;
    // z-index: 100;
    // bottom: -0.24rem;
    // left: 0.2rem;
    width: 0.49rem;
    height: 0.49rem;
    margin-right: 0.2rem;
    flex-shrink: 0;
  }
}

.header_logo_img {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  object-fit: cover;
  border: 1px solid #ffffff;
  background-color: #fff;

  @include base.respond-to(phone) {
    width: 0.68rem;
    height: 0.68rem;
    border-radius: 0.13rem;
    border: 1px solid var(--neutral-color-border, #e5e6eb);
  }
}

.header_title_num_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-right: 40px;
}

.header_title_right {
  position: absolute;
  right: 100px;
  top: 16px;
  z-index: 3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_title_num {
  color: #121212;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.24px;
  margin-right: 5px;
}

.header_title_string {
  color: #7d7d7d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.15px;
  position: relative;
  top: 2px;
}

.header_title {
  color: #121212;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-top: 6.5px;
  max-width: 800px;

  @include base.respond-to(phone) {
    color: #ffffff;
    font-size: 0.2rem;
    // width: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 0.25rem;
    margin-top: 0px;
  }
}

.header_subtitle {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  color: #515151;
  font-family: Product Sans;
  display: flex;

  @include base.respond-to(phone) {
    color: var(--neutral-color-text, #86909c);
    font-size: 0.13rem;
    font-family: Product Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 0.2rem;
    margin-top: 0.08rem;
    margin-bottom: 0;
    flex-wrap: wrap;
  }
}

.header_subtitle_item {
  @include base.split();
  flex-shrink: inherit;
  color: #515151;
  font-size: 16px;
}

.header_benefit_wrapper {
  display: flex;
  flex-wrap: wrap;

  @include base.respond-to(phone) {
    margin: 0 0.2rem;
    padding-bottom: 0.1rem;
  }
}

.header_benefit_item {
  flex-direction: row;
  align-items: center;
  padding: 7px 6px;
  gap: 3px;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.0075em;
  text-align: center;
  margin-right: 4px;
  margin-bottom: 5px;
  background: rgba(139, 139, 139, 0.8);

  @include base.respond-to(phone) {
    color: var(--neutral-color-text, #4e5969);
    font-size: 0.13rem;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    font-family: Product Sans;
    font-style: normal;
    line-height: 0.18rem;
    text-align: center;
    margin-right: 0.04rem;
    margin-bottom: 0.05rem;
    background: var(--neutral-color-fill, #f7f8fa);
    margin-top: 10px;
    margin-bottom: 16px;
  }
}

.split {
  width: 100%;
  border-bottom: 1px solid var(--neutral-color-border, #e5e6eb);
}

.header_mask {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(180deg, rgba(7, 7, 7, 0.15) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.header_title_verified {
  margin-left: 20px;
  position: relative;
  top: 5px;

  @include base.respond-to(phone) {
    margin-left: 0.1rem;
  }
}

.footer {
  text-align: center;
  height: 85px;
  line-height: 85px;
}

.jobs_chat_now_container {
  position: relative;
  width: fit-content;
  min-width: 110px;
  padding-bottom: 10px;
}

.salary {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.0075em;
  color: #2378e5;
}

.jobs_content {
  display: flex;
  align-items: center;

  :global {
    .tag_flag {
      border-radius: 6px;
      background: var(--neutral-color-fill, #f7f8fa);
      // padding: 5px 10px;
    }
  }
}

.jobs_item_title {
  flex: 1;
  width: 0;
  height: 23px;
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #1d2129;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  display: block;

  &:hover {
    color: #1d2129;
  }
}

.chat_now {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 73px;
  min-height: 28px;
  background: #2378e5;
  border-radius: 8px;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 6px;
  opacity: 0;
  transition: all 0.3s linear;
  padding: 0 8px;
  white-space: nowrap;
}

.header_right {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #7d7d7d;

  &:hover {
    color: #2378e5;

    .arrow {
      border-left-color: #2378e5;
    }
  }
}

.arrow {
  border-left: 5px solid #7d7d7d;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  margin-left: 5px;
  width: 0;
  height: 0;
}

.more_job {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.0075em;
  text-decoration-line: underline;
  color: #1d2129;
}

@include msd {
  .container {
    min-height: calc(100vh - 67px - 68px);
    img {
      font-size: 0;
      border-radius: 12px;
      border: 1px solid #fff;
    }

    color: #515151;
    font-size: 16px;
    line-height: 22px;

    .coverPic {
      width: 100%;
      height: auto;
    }

    .mobileHeader {
      padding: 28px 20px 24px 20px;
      background: linear-gradient(100deg, #daedfc 3.52%, #efecfe 96.89%);

      .info {
        display: flex;
        justify-content: space-between;

        h1 {
          color: #121212;
          font-size: 24px;
          font-weight: 700;
          line-height: 26px;
        }

        .logo {
          width: 56px;
          margin-left: 10px;
        }
      }

      .subtitle {
        color: #515151;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 8px;

        span {
          padding-right: 16px;
          position: relative;

          &::after {
            content: " ";
            width: 3px;
            height: 3px;
            border-radius: 50%;
            position: absolute;
            left: -8px;
            top: 8px;
            background-color: #515151;
          }

          &:first-child {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
