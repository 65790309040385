@use '../../base';

.tab_content_wrapper {
  width: 752px;
  padding: 28px;
  background-color: #fff;
  margin-right: 16px;
  border-radius: 8px;
  color: #515151;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  @include base.respond-to(phone) {
    width: 100%;
    padding: 0;
    border-radius: 0px;
    // padding-bottom: 0.5rem;
  }
}

.album_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  :global {
    img {
      cursor: pointer;
    }
  }
}

.album_item {
  border-radius: 5px;
}

.overview_item {
  padding: 8px 12px;
  width: 221px;
  height: 63px;
  background: #f7f8fa;
  border-radius: 4px;

  @include base.respond-to(phone) {
    width: 1.7rem;
    border-radius: 6px;
    background: #F6F6F6;
    height: 59px;
  }

  &_value_PC {
    &:hover {
      text-decoration: underline;
      color: #2378e5;
    }
  }
}

.BusinessInformation {
  border-radius: 8px;
  background: #F7F8FA;
  padding: 20px;

  .business_item {
    width: 326px;
    padding: 0;
  }

  @include base.respond-to(phone) {
    margin-bottom: 0;
    padding: 12px;
  }
}

.overview_item_name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7D7D7D;
  margin-bottom: 4px;
  @extend .text_overflow;

  @include base.respond-to(phone) {
    color: #7D7D7D;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.overflow_width {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #1d2129;
  @extend .text_overflow;

  @include base.respond-to(phone) {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #121212;
    width: 3.7rem;
  }
}

.overview_item_value {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #1d2129;
  @extend .text_overflow;

  @include base.respond-to(phone) {
    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 0;
  }

  :global {
    a {
      color: #1d2129 !important;

      &:hover {
        color: #1890ff !important;
      }
    }
  }
}

.overview_item_value_mobile {
  color: var(--neutral-color-text, #1d2129);
  /* Regular/title/medium and small */
  font-size: 0.17rem;
  font-family: Product Sans;
  font-style: normal;
  font-weight: 400;
  line-height: 0.22rem;
  word-break: break-all;
}

.overview_item_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px 4px;

  @include base.respond-to(phone) {
    gap: 8px 0;
  }
}

.introduction {
  line-height: 23px;
  white-space: pre-wrap;

  @include base.respond-to(phone) {
    font-weight: 400;
    line-height: 22px;
    color: #515151;
  }
}

.introduce_wrapper {
  overflow: hidden;
  transition: all 0.3s linear;
  line-height: 23px;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.containerMore {
  display: flex;
  justify-content: center;

  @include base.respond-to(phone) {
    padding-top: 10px;
  }
}

.more {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2378E5;
  margin-top: 8px;
  cursor: pointer;
  position: relative;
  padding-right: 22px;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #2378E5;
    right: 0px;
    bottom: 7px;
  }

  @include base.respond-to(phone) {
    color: #2378E5;
    font-family: Product Sans;
    font-style: normal;
    font-weight: 400;
    margin-top: 0;
  }
}

.moreInfo {

  &:before {
    display: none;
  }
}

.lessMore {
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #2378E5;
    right: 0px;
    bottom: 8px;
  }
}

.business_item {
  width: 346px;
  border-radius: 4px;
  color: #121212;

  @include base.respond-to(phone) {
    height: auto;
    width: 3.5rem;
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.animation_wrapper {
  overflow: hidden;
  transition: height 0.3s linear;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.mobile_album {
  width: 3rem;
  height: 2.1rem;
  position: relative;
  border-radius: 5px;
}