.mask {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .25);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 700px;
  max-width: 90%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  border-radius: 9px;
  background: #FFF;
  padding: 32px;

  .title {
    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    line-height: 24px;
    justify-content: space-between;
    padding-bottom: 16px;

    span {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
    padding-bottom: 20px;
    color: #515151;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}