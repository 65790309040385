@use '../../base';
@import 'styles/mixins';

.social_ {
  padding: 28px;
  background-color: #fff;
  margin-top: 16px;
  border-radius: 8px;

  @include base.respond-to(phone) {
    margin: 0 0.2rem;
    // padding-bottom: 0.5rem;
  }
}

.culture_wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 20px 14px;
  width: 368px;

  @include base.respond-to(phone) {
    border: none;
    width: auto;
    padding: 0 0 0;
    border-radius: 0.08rem;
    // margin: 0 0.2rem;
    // padding-bottom: 0.5rem;
  }

  .social_item {
    display: flex;
    align-items: center;

    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }

  &.social_item:first-of-type {
    background: #fff000;
  }

  .btn_more {
    display: flex;
    justify-content: start;
    align-items: start;

    &:hover {
      cursor: pointer;
    }

    .btn_more_text {
      display: inline-block;
      margin-right: 4px;
      padding: 6px 0;
      font-size: 14px;
      color: #2378E5;
    }

    .right_icon {
      align-self: center;
      margin-bottom: 4px;
    }
  }
}

.benefitsBox {
  background-color: #fff;
  padding: 28px;
  margin-bottom: 16px;
  border-radius: 8px;
  width: 100%;
}

.social_icon {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85);

  span {
    padding-left: 15px;
  }

  &:hover {
    color: #2378E5;

  }

  &>img {
    transform: scale(1.28);
    border-radius: 8px !important;
  }
}

.social_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px 0;
  width: 100%;

  border-top: 1px solid #e5e6eb;
  padding-top: 22px;
  margin-top: 22px;

  @include base.respond-to(phone) {
    padding-bottom: 20px;
    border-bottom: 1px solid #E5E6EB;
  }
}

.culture_title {
  margin-bottom: 8px;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #121212;

  @include base.respond-to(phone) {
    color: var(--neutral-color-text, #1d2129);
    font-size: 20px;
    font-family: Product Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 0.25rem;
    margin-bottom: 0.18rem !important;
    padding-top: 24px;
  }
}

.culture_content {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.0075em;
  color: #4e5969;
  padding-bottom: 24px;
  border-bottom: 1px solid #e5e6eb;
  margin-bottom: 24px;
}

.subtitle {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #121212;
  padding-bottom: 16px;
}

.item_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 6px;

  @include base.respond-to(phone) {
    justify-content: unset;
    margin-bottom: 0;
  }
}

.item {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0px 10px !important;
  height: 26px;
  line-height: 26px;
  color: #515151;
  background: #f7f8fa;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;


  span {
    line-height: 37px;
    @include text_overflow;
    font-size: 12px;
    max-width: 278px;
    color: #515151;
  }

  @include base.respond-to(phone) {
    width: auto;
    height: 28px;
    line-height: 28px;
    border-radius: 4px;
    padding: 0 10px;

    span {
      font-size: 12px;
      line-height: 28px;
    }
  }
}

.culture {
  // .item {

  //   background: #FFF9F4;

  //   span {
  //     color: #FF7D00;
  //   }
  // }

  @include base.respond-to(phone) {
    display: flex;
    gap: 6px;
    margin: 0;
    justify-content: unset;
  }

  .item {



    @include base.respond-to(phone) {
      border-radius: 4px;
      background: #F6F6F6;
      color: #515151;
      font-size: 12px !important;
      width: auto;
      padding: 0 10px;
      margin: 0;
    }
  }
}

.link_text {
  margin-left: 20px;
  color: #4e5969;
  width: 280px;
  @extend .text_overflow;
}

.text_overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.contact {
  // border-bottom: 1px solid #E5E6EB;
  width: 100%;
  // padding-bottom: 22px;
  // margin-bottom: 30px;

  .contactItem {
    min-height: 70px;
    border-radius: 6px;
    background: #F6F6F6;
    padding: 12px;
    display: block;
    margin-bottom: 8px;

    a {
      display: block;

      &:hover {
        .link {
          text-decoration: underline;
          color: #2378e5;

        }

      }

    }

    .title {
      color: #86909C;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0.113px;
      padding-bottom: 4px;
    }

    .link {
      color: #1D2129;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.128px;
    }

    .phone_item {
      color: #1D2129;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.128px;
      margin-top: 4px;
    }
  }

  .contactItemWebsite {
    &:hover {
      background: #f4f8fe;
    }
  }
}

.news {
  margin-top: 16px;
  width: 368px;
  border-radius: 8px;
  background-color: #fff;
  padding: 28px;

  @include base.respond-to(phone) {
    margin-top: 0;
    padding: 0 0.2rem 0.3rem 0.2rem;
  }

  .newsList {
    margin-bottom: 20px;
    display: block;

    &:hover {
      .title {
        color: #2378E5;
      }

      .content {
        text-decoration: underline;
      }
    }

    .title {
      color: #121212;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.287px;
      letter-spacing: 0.15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-bottom: 10px;

      @include base.respond-to(phone) {
        margin-top: 0;
      }
    }

    .content {
      color: #515151;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.105px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding-bottom: 0 !important;
    }

    .date {
      color: #B8B8B8;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.287px;
      /* 194.058% */
      letter-spacing: 0.09px;
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
    }
  }
}

.containerMore {
  display: flex;
  justify-content: center;
}

.more {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2378E5;
  margin-top: 8px;
  cursor: pointer;
  position: relative;
  padding-right: 22px;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #2378E5;
    right: 0px;
    bottom: 7px;
  }

  @include base.respond-to(phone) {
    color: #2378E5;
    font-family: Product Sans;
    font-style: normal;
    font-weight: 400;
    margin-top: 0;
  }
}

.lessMore {
  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #2378E5;
    right: 0px;
    bottom: 8px;
  }
}